// Yea you're right I stole this
// https://codepen.io/jmak/pen/LsCet

$spinner-color: #69717d !default;
$spinner-size: 48px !default;

.spinner {
	font-size: $spinner-size;
	position: relative;
	display: inline-block;
	width: 1em;
	height: 1em;

	&.center {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		padding-top: 2rem;
	}

	.spinner-blade {
		position: absolute;
		left: 0.4629em;
		bottom: 0;
		width: 0.074em;
		height: 0.2777em;
		border-radius: 0.0555em;
		background-color: transparent;
		transform-origin: center -0.2222em;
		animation: spinner-fade 1s infinite linear;

		$animation-delay: 0s;
		$blade-rotation: 0deg;

		@for $i from 1 through 12 {
			&:nth-child(#{$i}) {
				animation-delay: $animation-delay;
				transform: rotate($blade-rotation);
				$blade-rotation: $blade-rotation + 30;
				$animation-delay: $animation-delay + 0.083;
			}
		}
	}
}

@keyframes spinner-fade {
	0% {
		background-color: $spinner-color;
	}

	100% {
		background-color: transparent;
	}
} ;
