$img-dim: 1.5rem;
$img-padding: 0.5rem;

.main-contain .dock {
	position: absolute;
	bottom: 0px;
	text-align: center;
	display: flex;
	justify-content: center;
	width: 100%;
	left: 0;
	margin-bottom: 0.5rem;
}

.main-contain .dock-nav {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0px;
	width: fit-content;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	background: rgb(40 40 40 / 21.5%);
	box-shadow: inset 0 0 0.1em rgba(255, 255, 255, 0.66);
	border: solid 1px rgb(40 40 40 / 16.25%);
	border-radius: 0.75rem;
	backdrop-filter: blur(5px);
	padding: 0.5rem;
	height: 2rem;
}

.main-contain .dock-nav ul {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	gap: 0.875rem;
}

.main-contain .dock-nav ul li {
	width: 2rem;
	height: 2rem;
	display: grid;
	place-items: center;
	border-radius: 20%;
	color: #242424;
	user-select: none;
	transform-origin: bottom center;
	overflow: hidden;
}

.main-contain .dock-nav ul li img {
	width: 100%;
	height: 100%;
}

.main-contain .dock-nav ul li.img-padding img {
	padding: 15%;
	border-radius: 25%;
	overflow: hidden;
	box-sizing: border-box;
	background: #fafafa;
}

.main-contain .dock-nav ul li:hover {
	&:after {
		content: attr(data-title);
		position: absolute;
		color: #fff;
		background: rgba(64, 64, 64, 0.85);
		font-size: 1rem;
		top: -150%;
		position: absolute;
		width: fit-content;
		white-space: nowrap;
		border-radius: 0.25rem;
		padding: 0.25rem 0.75rem;
		border: rgb(48, 48, 48) solid 1px;
		box-shadow: inset rgba(255, 255, 255, 0.5) 0 0 0.1em;
	}
	&:before {
		content: "";
		z-index: 2;
		width: 0.75rem;
		height: 0.75rem;
		position: absolute;
		top: calc(-150% + 1.75rem);
		background: rgba(64, 64, 64, 0.85);

		clip-path: polygon(100% 0, 0 0, 50% 66%);
		border-right: rgb(48, 48, 48) solid 1px;
		border-left: rgb(48, 48, 48) solid 1px;
	}
}

.main-contain .dock-nav ul .separator {
	width: 0.1rem;
	height: $img-dim + ($img-padding);
	border-radius: 1000rem;
	background: rgb(255 255 255 / 50%);
	margin: 0 0.0675rem;
}

@media (max-width: 640px) {
	.main-contain {
		.dock-nav {
			margin-bottom: -0.5rem;
			border-radius: 0.25rem;
		}
	}
}
