.menu-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	z-index: 10;
	background: rgba(40, 40, 40, 0.25);
	backdrop-filter: blur(15px);
	color: #fff;
	width: 100%;
	padding: 0.05rem;
	height: 1.6rem;
	box-sizing: border-box;
	font-size: 0.825rem;
	font-weight: 500;
	top: -1px;
	.right-side * {
		cursor: default;
	}
	img {
		height: 70%;
		padding: 0.1rem;
		filter: invert(1);
	}

	.app-menus .apple {
		height: 70%;
		padding: 0.1rem;
		margin-right: 0.125rem;
		margin-left: 0.125rem;
	}

	.app-menus .menu-trigger,
	.app-menus .img-container {
		&:nth-child(2) {
			font-weight: 700;
		}
		margin-left: 1rem;
	}
	.right-side {
		float: right;
		> div {
			margin-right: 0.5rem;
			img {
				height: 50%;
				padding: 0.25rem;
				opacity: 0.8;
			}
			&:nth-child(4) {
				img {
					height: 70%;
					padding: 0.2rem;
				}
			}
			&:last-child {
				margin-right: 1.25rem;
				font-size: 0.825rem;
			}
			.time {
				margin-left: 0.4em;
			}
		}
	}
	.app-menus,
	.right-side {
		height: 100%;
		display: inline-block;
		> div {
			height: 100%;
			display: inline-block;
			vertical-align: top;
		}
	}
	.app-menus,
	.right-side {
		height: 100%;
		display: flex;
		align-items: center;
		> div {
			height: min-content;
			display: inline-block;
			vertical-align: top;
		}
	}
	.img-container {
		height: 100% !important;
		display: flex !important;
		align-items: center;
	}
}

button {
	all: unset;
	cursor: pointer;
}

@media (max-width: 640px) {
	.menu-bar {
		display: none;
	}
}
